<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        The mean molar mass of the atmosphere at the surface of planet X-913, is
        <number-value :value="molarMass" unit="\text{g/mol.}" />
        The surface temperature and pressure on the planet are
        <number-value :value="temp" unit="\text{K}" /> and
        <number-value :value="pressure" unit="\text{atm,}" />
        respectively. Assuming ideal behaviour, calculate the density of Titan's atmosphere.
      </p>

      <calculation-input
        v-model="inputs.density"
        prepend-text="$\text{Density}:$"
        append-text="$\text{g/L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question396',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        density: null,
      },
    };
  },
  computed: {
    molarMass() {
      return this.taskState.getValueBySymbol('molarMass').content;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
  },
};
</script>
